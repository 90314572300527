import React, { PropsWithChildren } from 'react';

import { AuthContext } from 'modules/domain/auth/context';
import { useAuthStorage } from 'modules/domain/auth/use-auth-storage';

export const AuthProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const { auth, setAuthInfo, clearAuthInfo } = useAuthStorage();

  return (
    <AuthContext.Provider value={{ auth, setAuthInfo, clearAuthInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
