import { useCallback } from 'react';

import { useAuthContext } from './use-auth-context';

export const useSignOut = () => {
  const { clearAuthInfo } = useAuthContext();

  const signOut = useCallback(() => {
    clearAuthInfo();
    window.location.reload();
  }, [clearAuthInfo]);

  return signOut;
};
