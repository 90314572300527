import { UserDto } from 'modules/api/dto';
import { getUserAge, getUserLocationString } from 'modules/utils';

import { User } from './types';

export const mapUserDtoToUser = (userDto: UserDto): User => ({
  id: userDto.id,
  name: userDto.name,
  age: getUserAge(userDto.birthday),
  birthday: userDto.birthday,
  location: getUserLocationString(userDto.city, userDto.country),
  thumbnail: userDto.thumbnail,
  tags: userDto.tags, // will be extended by user tags request later
  experiments: [], // initial data, will be overridden later
  occupation: userDto.occupation,
  timezone: userDto.timezone,
  gender: userDto.gender,
  language: userDto.languages[0],

  info: {
    aboutMe: userDto.about,
    interests: [], // initial data, will be overridden later
    lifeStory: null, // initial data, will be overridden later
    eye: userDto.eye,
    hair: userDto.hair,
    drink: userDto.drink,
    smoke: userDto.smoke,
    education: userDto.education,
    relationship: userDto.relationship,
    haveKids: userDto.haveKids,
    bodytype: userDto.bodytype,
    languages: userDto.languages,
    height: userDto.height,
    communicationPurpose: userDto['communication-purpose'],
  },
});
