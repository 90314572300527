import React from 'react';
import Lottie from 'lottie-react';

import { Loader } from 'modules/components/loader';

import { useAnimationData } from './use-animation-data';
import styles from './index.module.css';

type Props = {
  animationPath: string;
};

export const AnimatedGift = (props: Props) => {
  const { animationPath } = props;

  const animationData = useAnimationData(animationPath);

  if (!animationData) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Lottie animationData={animationData} />
    </div>
  );
};
