import { calendarModel } from './model';

const { actions, selectors } = calendarModel;

export const { setDaysWithMessages } = actions;
export const { getDaysWithMessages } = selectors;

export type { CalendarModelState, CalendarDayWithMessages } from './types';

export { calendarModel as calendarSlice };
