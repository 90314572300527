import React, { memo } from 'react';

import { testId } from 'modules/utils';
import { UserExperimentTag } from 'modules/api/dto';
import { useLocalizedUserData, useUserHasExperimentTag } from 'modules/domain/users/hooks';

import { InfoBlock } from './info-block';
import { AdditionalInfoBlock } from './additional-info-block';
import styles from './index.module.scss';

type Props = {
  ownerId: string;
  attendeeId: string;
};

export const AccordionTabInfo = memo((props: Props) => {
  const { ownerId, attendeeId } = props;

  const { info: userInfo, occupation } = useLocalizedUserData(ownerId) || {};

  const withCommunicationPurpose = useUserHasExperimentTag(
    attendeeId,
    UserExperimentTag.CommunicationPurposes,
  );

  const renderBlocks = () => {
    const blocks: JSX.Element[] = [];

    if (withCommunicationPurpose && userInfo?.communicationPurpose) {
      blocks.push(
        <InfoBlock key="communication-purposes" title="Here to...">
          {userInfo.communicationPurpose}
        </InfoBlock>,
      );
    }

    if (userInfo?.aboutMe) {
      blocks.push(
        <InfoBlock key="about-me" title="About Me">
          {userInfo.aboutMe}
        </InfoBlock>,
      );
    }

    if (userInfo?.interests?.length) {
      blocks.push(
        <InfoBlock key="interests" title="Interests">
          {userInfo.interests.join(', ')}
        </InfoBlock>,
      );
    }

    blocks.push(<AdditionalInfoBlock key="info" info={userInfo} occupation={occupation} />);

    return blocks;
  };

  return (
    <div {...testId(`user-tab-info-${ownerId}`)} className={styles.container}>
      {renderBlocks()}
    </div>
  );
});
