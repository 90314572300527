import { spotifyMatchModel } from './model';

const { actions, selectors } = spotifyMatchModel;

export const { setSpotifyMatchResult } = actions;
export const { getSpotifyMatchResult } = selectors;

export type { SpotifyMatchModelState, SpotifyMatchResult } from './types';
export { SpotifyScoreLevel } from './types';

export { mapSpotifyMatchDtoToResult } from './adapter';

export { spotifyMatchModel as spotifyMatchSlice };
