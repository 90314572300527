import { dictionariesModel } from './model';

const { actions, selectors } = dictionariesModel;

export const { setDictionary } = actions;
export const { getDictionaries, getDictionary } = selectors;

export type { DictionariesList, Dictionary } from './types';
export { DictionaryName } from './types';

export { dictionariesModel as dictionariesSlice };
