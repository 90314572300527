import { dialogNotesModel } from './model';

const { actions, selectors } = dialogNotesModel;

export const { setDialogNotes } = actions;
export const { getDialogNotes } = selectors;

export type {
  DialogNotes,
  DialogNoteValue,
  DialogNotesList,
  DialogNotesModelState,
  DialogNotesTopic,
} from './types';

export {
  mapDialogNotesDtoToNotes,
  mapDialogNotesToPatchPayload,
  generateDialogNoteValueId,
} from './adapter';

export { dialogNotesModel as dialogNotesSlice };
