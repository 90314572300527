import { ChatInputModelState } from './types';

export const setInputTextState = (
  state: ChatInputModelState,
  inputText: string,
  append?: boolean,
) => {
  if (append) {
    state.inputText += inputText;
  } else {
    state.inputText = inputText;
  }
};
