import { copilotModel } from './model';

const { actions, selectors } = copilotModel;

export const {
  setCopilotData,
  resetCopilotData,
  setCopilotDataLoadingStart,
  setCopilotDataLoadingFailed,
  setCopilotTextOptionSelected,
  resetCopilotLastUsage,
} = actions;
export const { getCopilotData, getCopilotLastUsageData } = selectors;

export { mapAutoGeneratedAnswersDto } from './adapter';

export type {
  CopilotModelState,
  CopilotData,
  CopilotLastUsageData,
  CopilotOption,
  CopilotAnalyticsData,
} from './types';
export { CopilotOptionType, CopilotUsage } from './types';

export { copilotModel as copilotSlice };
