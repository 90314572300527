import { useCallback, useState } from 'react';

import { useSendDialogPhotoMessage } from 'modules/domain/messages/hooks';
import { useModalActions } from 'modules/domain/modals/hooks';
import { parseDialogId } from 'modules/utils';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { useMediaAnalytics } from 'modules/domain/media/hooks';

export const useSendDialogMedia = (dialogId: string) => {
  const [messageSending, setMessageSending] = useState(false);

  const { sendPhotoMessage } = useSendDialogPhotoMessage(dialogId);
  const { trackAllLibraryMediaSent } = useMediaAnalytics();
  const { closeModal } = useModalActions();

  const sendDialogMedia = useCallback(
    async (baseUrl: string, lastMediaLength: number, copilotUsed?: boolean) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      setMessageSending(true);

      try {
        await sendPhotoMessage(baseUrl, copilotUsed);

        closeModal();

        if (lastMediaLength === 1) {
          trackAllLibraryMediaSent(animatorId, attendeeId, baseUrl);
        }
      } catch (error) {
        // the error is handled inside the sendPhotoMessage
        showChatErrorToast(
          'Failed to send a photo. Please check your connection and try again later',
        );
      }

      setMessageSending(false);
    },
    [sendPhotoMessage, trackAllLibraryMediaSent, dialogId, closeModal],
  );

  return { sendDialogMedia, messageSending };
};
