import React, { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'modules/navigation';

import { useClientInactivityTime } from './use-client-inactivity-time';

export const MajorPage = (props: PropsWithChildren) => {
  const { children } = props;

  const activityStatus = useClientInactivityTime();
  const navigate = useNavigate();

  useEffect(() => {
    if (activityStatus === 'inactive') {
      navigate(AppRoutes.Offline);
    }
  }, [navigate, activityStatus]);

  return <>{children}</>;
};
