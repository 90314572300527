import { dialogPreferencesModel } from './model';

const { actions, selectors } = dialogPreferencesModel;

export const {
  setDialogExplicityPreferences,
  setAnimatorPreferences,
  setAnimatorStoryTold,
  setDialogExplicitStatus,
} = actions;
export const {
  getAnimatorPreferences,
  getDialogAnimatorStoryTold,
  getIsExplicitPossibleForClient,
  getDialogIsExplicit,
  getCanToggleExplicit,
} = selectors;

export type {
  DialogPreferences,
  DialogPreferencesModelState,
  AnimatorPreferences,
  AnimatorPreferencesArea,
  DialogExplicityPreferences,
} from './types';

export {
  mapDialogAnimatorPreferencesDto,
  getAnimatorStoryToldFromDto,
  mapDialogDtoToExplicityPreferences,
} from './adapter';

export { dialogPreferencesModel as dialogPreferencesSlice };
