import { CopilotOption } from 'modules/domain/copilot/model';

import { formatSpecialOptionTextForAnalytics } from './format-special-option-text-for-analytics';

export const transformOptionsForCopilotAnalytics = (
  options: CopilotOption[],
  specialOption: CopilotOption | undefined,
) => {
  const optionsTexts = options.map(option => option.text);

  if (specialOption) {
    optionsTexts.push(formatSpecialOptionTextForAnalytics(specialOption.type));
  }

  return optionsTexts;
};
