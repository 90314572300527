import { useCallback } from 'react';

import { showChatErrorToast } from 'modules/components/chat/helpers';
import { resetDialogMediaState as resetDialogMediaStateAction } from 'modules/domain/media/model';
import { useDispatchAction } from 'modules/hooks/redux';

import { getPhotoReference } from '../helpers';
import { MessageType, SendDialogMessageData } from '../model';

import { useSendDialogMessage } from './use-send-dialog-message';

export const useSendDialogPhotoMessage = (dialogId: string) => {
  const sendDialogMessage = useSendDialogMessage(dialogId);
  const resetDialogMediaState = useDispatchAction(resetDialogMediaStateAction);

  const sendPhotoMessageBase = useCallback(
    async (data: Omit<SendDialogMessageData, 'type' | 'text'>) => {
      try {
        await sendDialogMessage({
          ...data,
          type: MessageType.PHOTO,
        });

        // TODO: once again relying on a timer:(
        // it takes some time at the backend side to update media state after message sent
        // replace it with the WS event listener once we have Websocket implemented
        setTimeout(resetDialogMediaState, 500);
      } catch (error) {
        // the error is handled inside the sendPhotoMessageBase

        showChatErrorToast(
          'Failed to send a photo. Please check your connection and try again later',
        );
      }
    },
    [sendDialogMessage, resetDialogMediaState],
  );

  const sendPhotoMessage = useCallback(
    (basename: string, copilotUsed?: boolean) => {
      return sendPhotoMessageBase({
        meta: { reference: getPhotoReference(basename) },
        copilotUsed,
      });
    },
    [sendPhotoMessageBase],
  );

  const sendPhotoMessageWithFileUpload = useCallback(
    (file: File, copilotUsed?: boolean) => {
      return sendPhotoMessageBase({ file, copilotUsed });
    },
    [sendPhotoMessageBase],
  );

  return {
    sendPhotoMessage,
    sendPhotoMessageWithFileUpload,
  };
};
