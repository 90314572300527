import { CopilotLastUsageData, CopilotUsage } from 'modules/domain/copilot/model';

export const getCopilotUsage = (
  specialOptionUsed: boolean,
  lastUsageData: CopilotLastUsageData | null,
): CopilotUsage => {
  // the order is important here
  if (specialOptionUsed) {
    return CopilotUsage.Used;
  } else if (lastUsageData?.edited) {
    return CopilotUsage.Edited;
  } else if (lastUsageData) {
    return CopilotUsage.Used;
  }

  return CopilotUsage.NotUsed;
};
