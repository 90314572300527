import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';

import { FastAnswer, FastAnswersList, FastAnswersModelState } from './types';

export type SetFastAnswersAction = PayloadAction<{
  data: FastAnswersList;
}>;
type SetFastAnswerSelectedAction = PayloadAction<{ fastAnswer: FastAnswer }>;

const initialState: FastAnswersModelState = {
  data: null,
  selectedFastAnswer: null,
};

const resetSelectedFastAnswer = (state: FastAnswersModelState) => {
  state.selectedFastAnswer = initialState.selectedFastAnswer;
};

export const fastAnswersModel = createSlice({
  name: 'fastAnswers',
  initialState,
  reducers: {
    setFastAnswers: (state, action: SetFastAnswersAction) => {
      const { data } = action.payload;
      state.data = data;
    },
    setFastAnswerSelected: (state, action: SetFastAnswerSelectedAction) => {
      const { fastAnswer } = action.payload;
      state.selectedFastAnswer = { ...fastAnswer };
    },
    resetSelectedFastAnswer,
  },
  extraReducers: addResetDialogStateReducer(resetSelectedFastAnswer),
  selectors: {
    getFastAnswers: state => state.data,
    getSelectedFastAnswer: state => state.selectedFastAnswer,
  },
});
