import { IcebreakerType } from 'modules/api/dto';
import { useSendDialogMessage } from 'modules/domain/messages/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { MessageType } from 'modules/domain/messages/model';

export const useSendIcebreakerMessage = (dialogId: string) => {
  const sendDialogMessage = useSendDialogMessage(dialogId);

  const sendIcebreakerMessage = async (icebreakerType: IcebreakerType, copilotUsed: boolean) => {
    try {
      await sendDialogMessage({
        text: 'Sent you a PurPur Card',
        meta: { icebreakerType },
        type: MessageType.ICEBREAKER,
        copilotUsed,
      });
    } catch (error) {
      showChatErrorToast(
        'Failed to send a PurPur Card. Please check your connection and try again later',
      );
    }
  };

  return sendIcebreakerMessage;
};
