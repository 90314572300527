import { useState, useEffect } from 'react';

import { useConfig } from 'modules/config';

type Activity = 'active' | 'inactive';

export const useClientInactivityTime = () => {
  const { maxInactivityTime } = useConfig();
  const [activity, setActivity] = useState<Activity>('active');

  useEffect(() => {
    let timeout: number | undefined;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = window.setTimeout(() => setActivity('inactive'), maxInactivityTime);
    };

    resetTimeout();
    document.addEventListener('keydown', resetTimeout);
    document.addEventListener('mousemove', resetTimeout);

    return () => {
      document.removeEventListener('keydown', resetTimeout);
      document.removeEventListener('mousemove', resetTimeout);
      clearTimeout(timeout);
    };
  }, [maxInactivityTime]);

  return activity;
};
