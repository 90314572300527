import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type Props = {
  label: string;
  id: string;
  name: string;
  value: string;
  style?: React.CSSProperties;
  checked: boolean;
};

export const RadioInput = (props: Props) => {
  const { label, id, name, value, style, checked } = props;

  return (
    <div className={styles.radioWrapper} style={style}>
      <input className={styles.radioInput} name={name} value={value} type="radio" id={id} />
      <label
        className={classNames(styles.radioLabel, {
          [styles.radioLabelDefault]: !checked,
          [styles.radioLabelSelected]: checked,
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};
