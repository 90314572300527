import { giftsModel } from './model';

const { actions, selectors } = giftsModel;

export const { setDialogGifts, setGiftsPriceLimit } = actions;
export const { getDialogGiftsState } = selectors;

export type { GiftsModelState, DialogGiftsState } from './types';

export { giftsModel as giftsSlice };
