import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';
import { useDispatchAction } from 'modules/hooks/redux';

import { setUserLifeStory as setUserLifeStoryAction } from '../model';

export const useUserLifeStoryApi = () => {
  const setUserLifeStory = useDispatchAction(setUserLifeStoryAction);
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useUserStoryApi');

  const fetchLifeStory = useCallback(
    async (userId: string) => {
      try {
        const lifeStory = await usersApi.getUserLifeStory(userId);
        setUserLifeStory({ userId, lifeStory });
      } catch (error) {
        logError('fetchLifeStory error', { animatorId: userId, error });
      }
    },
    [setUserLifeStory, usersApi, logError],
  );

  return {
    fetchLifeStory,
  };
};
