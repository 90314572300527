import React from 'react';

import { useUserField } from 'modules/domain/users/hooks';

import { UserHeaderBase } from './base';

type Props = {
  attendeeId: string;
  animatorId: string;
};

export const AttendeeHeader = (props: Props) => {
  const { animatorId, attendeeId } = props;

  const location = useUserField(attendeeId, 'location');

  return (
    <UserHeaderBase
      type="attendee"
      animatorId={animatorId}
      attendeeId={attendeeId}
      location={location}
    />
  );
};
