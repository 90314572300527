import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useOperatorId } from 'modules/domain/auth';

import { setOperatorNeedOnboarding } from '../model';

const STORAGE_PREFIX = 'onboarding_completed_for_';

export function useOperatorOnboardingActions() {
  const operatorId = useOperatorId();
  const dispatch = useDispatch();
  const storageKey = STORAGE_PREFIX + operatorId;

  const actualizeOnboardingStatus = useCallback(() => {
    const currentOnboardingRecord = localStorage.getItem(storageKey) !== 'ok';
    dispatch(setOperatorNeedOnboarding({ needOnboarding: currentOnboardingRecord }));
  }, [storageKey, dispatch]);

  const finishOnboarding = useCallback(() => {
    localStorage.setItem(storageKey, 'ok');
    dispatch(setOperatorNeedOnboarding({ needOnboarding: false }));
  }, [storageKey, dispatch]);

  return {
    actualizeOnboardingStatus,
    finishOnboarding,
  };
}
