import React, { useCallback } from 'react';
import moment from 'moment';
import cn from 'classnames';

import {
  useOperatorNeedOnboarding,
  useOperatorOnboardingActions,
} from 'modules/domain/operator/hooks';
import { useModalActions } from 'modules/domain/modals/hooks';
import { ModalNames } from 'modules/domain/modals/model';
import { CalendarModalData } from 'modules/constants';

import { CloseIcon } from './close-icon';
import { moreThanAYearAgo } from './moreThanYearAgo';
import styles from './styles.module.scss';

type Props = {
  timestamp: number;
  attachOnboarding?: boolean;
  onTop?: boolean;
};

export const DayIndicator = (props: Props) => {
  const { timestamp, attachOnboarding, onTop } = props;

  const { finishOnboarding } = useOperatorOnboardingActions();
  const { openModal } = useModalActions();
  const operatorNeedOnboarding = useOperatorNeedOnboarding();

  const onDayClick = useCallback(() => {
    if (moreThanAYearAgo(timestamp)) {
      return;
    }
    const modalData: CalendarModalData = { timestamp };
    openModal(ModalNames.Calendar, modalData);

    if (operatorNeedOnboarding) {
      finishOnboarding();
    }
  }, [timestamp, openModal, finishOnboarding, operatorNeedOnboarding]);

  const onboardingVisible = attachOnboarding && operatorNeedOnboarding;

  const timeString = moment(timestamp).calendar('', {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'MMM D YYYY',
  });

  return (
    <div
      className={cn(styles.container, {
        [styles.onTop]: onTop,
        [styles.onboarding]: onboardingVisible,
      })}
      onClick={onDayClick}
    >
      {timeString}
      {onboardingVisible && (
        <div className={styles.onboardingHint} onClick={event => event.stopPropagation()}>
          Click on the dates to open the calendar and jump to a specific date!
          <CloseIcon className={styles.closeButton} onClick={finishOnboarding} />
        </div>
      )}
    </div>
  );
};
