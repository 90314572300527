import { dialogModel } from './model';

const { actions, selectors } = dialogModel;

export const {
  setCurrentDialog,
  setDialogsQueueIsEmpty,
  setFoundDialog,
  resetFoundDialog,
  resetDialogState,
} = actions;
export const {
  getCurrentDialog,
  getDialogsQueueIsEmpty,
  getFoundDialog,
  getUnansweredDialogsCount,
} = selectors;

export type { Dialog, DialogModelState, InitDialogStateData } from './types';
export { mapDialogsResponseDtoToInitData } from './adapter';
export { addResetDialogStateReducer } from './utils';

export { dialogModel as dialogSlice };
