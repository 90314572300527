import { useEffect } from 'react';

import { useUserHasTag } from 'modules/domain/users/hooks';
import { UserTag } from 'modules/api/dto';

import { useSpotifyApi } from './use-spotify-api';

export const useSpotifyMatchResultLoader = (animatorId: string, attendeeId: string) => {
  const { loadSpotifyMatchResult } = useSpotifyApi();

  const hasSpotifyTag = useUserHasTag(attendeeId, UserTag.SpotifyConnected);

  useEffect(() => {
    if (!hasSpotifyTag) {
      return;
    }

    loadSpotifyMatchResult(animatorId, attendeeId);
  }, [loadSpotifyMatchResult, animatorId, attendeeId, hasSpotifyTag]);
};
