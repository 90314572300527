import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useToken } from 'modules/domain/auth/use-token';
import { AppRoutes } from 'modules/navigation/navigation-constants';

type Props = {
  children: JSX.Element;
};

export const PrivateRoute = (props: Props) => {
  const { children } = props;

  const token = useToken();
  const location = useLocation();

  const isAuthenticated = !!token;

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={AppRoutes.Login} replace state={{ from: location }} />
  );
};
