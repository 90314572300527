import { createSlice } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';

import { SpotifyMatchModelState, SpotifyMatchResult } from './types';

type SetMatchResultAction = ActionWithDialogId<{
  result: SpotifyMatchResult;
}>;

const initialState: SpotifyMatchModelState = {};

export const spotifyMatchModel = createSlice({
  name: 'spotifyMatch',
  initialState,
  reducers: {
    setSpotifyMatchResult: (state, action: SetMatchResultAction) => {
      const { dialogId, result } = action.payload;
      state[dialogId] = result;
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getSpotifyMatchResult: (state, dialogId: string) => state[dialogId],
  },
});
