import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { testId } from 'modules/utils/test-id';

import { LabelName, labelsConfig } from './constants';
import styles from './styles.module.scss';

type Props = {
  id: LabelName;
  textTemplateParam?: string | number;
};

export const LabelItem = (props: Props) => {
  const { id, textTemplateParam } = props;

  const { color = '#fff', backgroundColor, text, tooltip, testNodeId } = labelsConfig[id];

  const caption = typeof text === 'string' ? text : text(textTemplateParam);
  const testIdData = testNodeId ? testId(testNodeId) : testId(caption);

  if (!tooltip) {
    return (
      <span {...testIdData} className={styles.label} style={{ backgroundColor, color }}>
        {caption}
      </span>
    );
  }

  return (
    <>
      <span
        {...testIdData}
        data-tooltip-place="bottom-start"
        className={styles.label}
        style={{ backgroundColor, color }}
        data-tooltip-id={id}
      >
        {caption}
      </span>
      <ReactTooltip
        id={id}
        variant="light"
        className={styles.tooltipContainer}
        noArrow={true}
        delayHide={100}
      >
        <span>{tooltip}</span>
      </ReactTooltip>
    </>
  );
};
