import { getSpotifyMatchResult } from 'modules/domain/spotify-match/model';
import { useRootSelector } from 'modules/hooks/redux';
import { getDialogId } from 'modules/utils';

export const useSpotifyMatchResult = (animatorId: string, attendeeId: string) => {
  const dialogId = getDialogId(animatorId, attendeeId);
  const spotifyMatchResult = useRootSelector(state => getSpotifyMatchResult(state, dialogId));

  return spotifyMatchResult;
};
