import { useCallback } from 'react';

import { useAnnalsApi } from 'modules/api';

export const useDialogAnalytics = () => {
  const { sendWithOperatorId } = useAnnalsApi();

  const trackDialogIsExplicit = useCallback(
    (animatorId: string, attendeeId: string) => {
      const payload = {
        'animator-id': animatorId,
        'client-id': attendeeId,
      };
      return sendWithOperatorId('multichat-explicit-banner-checked', payload);
    },
    [sendWithOperatorId],
  );

  const trackSearchDialog = useCallback(
    (animatorId: string, attendeeId: string) => {
      const payload = {
        'animator-id': animatorId,
        'client-id': attendeeId,
      };
      return sendWithOperatorId('multichat-search-button-pressed', payload);
    },
    [sendWithOperatorId],
  );

  const trackAnimatorStoryTold = useCallback(
    (animatorId: string, attendeeId: string) => {
      const payload = {
        'animator-id': animatorId,
        'client-id': attendeeId,
      };
      return sendWithOperatorId('multichat-animator-story-told', payload);
    },
    [sendWithOperatorId],
  );

  return {
    trackDialogIsExplicit,
    trackSearchDialog,
    trackAnimatorStoryTold,
  };
};
