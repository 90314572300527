import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useOperatorId } from 'modules/domain/auth';
import { NetworkErrorStatus, HttpError, useApi } from 'modules/api';
import { parseDialogId, useLogger } from 'modules/utils';
import { DialogMessageDto } from 'modules/api/dto';
import { SendMessagePayload } from 'modules/api/payload';
import { useDialogLoadApi } from 'modules/domain/dialog/hooks';

import { setMessageSendingFailed, setDialogMessageSent, mapDialogMessageDto } from '../model';

export const useSendDialogMessageApi = (dialogId: string) => {
  const dispatch = useDispatch();
  const operatorId = useOperatorId();
  const { dialogsMessages: dialogsMessagesApi } = useApi();
  const { logError } = useLogger('useSendDialogMessageApi');
  const { loadNextDialog } = useDialogLoadApi();

  const sendDialogMessage = useCallback(
    async (draftMessage: DialogMessageDto) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      const message = mapDialogMessageDto(draftMessage, animatorId);
      dispatch(setDialogMessageSent({ dialogId, message }));

      try {
        const messageBody: SendMessagePayload = {
          tag: draftMessage.tag,
          text: draftMessage.text,
          reference: draftMessage.meta.reference,
          instant: 1,
          operator: operatorId,
          meta: draftMessage.meta,
        };

        await dialogsMessagesApi.sendMessage(animatorId, attendeeId, messageBody);
      } catch (error) {
        // Conflict means the dialog is taken by the other person or bot.
        // No need to log this error just to switch the dialog
        if ((error as HttpError).status === NetworkErrorStatus.Conflict) {
          loadNextDialog(operatorId);

          return;
        }
        logError('Failed to send a message', { error, message: draftMessage, dialogId });

        dispatch(setMessageSendingFailed({ dialogId, messageTag: draftMessage.tag }));

        throw error;
      }
    },
    [dialogsMessagesApi, dialogId, dispatch, operatorId, logError, loadNextDialog],
  );

  return { sendDialogMessage };
};
