import { createSlice } from '@reduxjs/toolkit';

import { GiftDto } from 'modules/api/dto';
import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';

import { DialogGiftsState, GiftsModelState } from './types';

type SetDialogGiftsAction = ActionWithDialogId<{
  gifts: GiftDto[];
}>;
type SetGiftsPriceLimitAction = ActionWithDialogId<{
  priceLimit: number;
}>;

const initialState: GiftsModelState = {};

export const giftsModel = createSlice({
  name: 'gifts',
  initialState,
  reducers: {
    setDialogGifts: (state, { payload: { dialogId, gifts } }: SetDialogGiftsAction) => {
      state[dialogId] = {
        ...state[dialogId],
        gifts,
      };
    },
    setGiftsPriceLimit: (
      state,
      { payload: { dialogId, priceLimit } }: SetGiftsPriceLimitAction,
    ) => {
      if (state[dialogId]?.priceLimit !== priceLimit) {
        state[dialogId] = {
          ...state[dialogId],
          priceLimit,
        };
      }
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getDialogGiftsState: (state, dialogId: string) =>
      state[dialogId] as DialogGiftsState | undefined,
  },
});
