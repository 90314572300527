import { Dialog } from 'modules/domain/dialog/model';
import { useMarkLastMessageAsRead } from 'modules/domain/messages/hooks';
import { useDialogMediaStatePoller } from 'modules/domain/media/hooks';
import { useGiftsLoader } from 'modules/domain/gifts/hooks';
import { useSpotifyMatchResultLoader } from 'modules/domain/spotify-match/hooks';

import { useUnansweredUnpaidMessageLoader } from './use-unanswered-unpaid-message-loader';
import { useMessagesPoller } from './use-messages-poller';
import { useMessagesNextPageLoader } from './use-messages-next-page-loader';

/**
 * Contains all chat/dialog async services, like pollers, loaders, trackers etc.
 */
export const useChatServices = (currentDialog: Dialog) => {
  const { id: dialogId, animatorId, attendeeId } = currentDialog;

  // loaders
  useGiftsLoader(currentDialog);
  useMessagesNextPageLoader(dialogId);
  useUnansweredUnpaidMessageLoader(dialogId);
  useSpotifyMatchResultLoader(animatorId, attendeeId);

  // pollers
  useMessagesPoller(dialogId);
  useDialogMediaStatePoller(dialogId);

  // trackers
  useMarkLastMessageAsRead(dialogId);
};
