import { messagesSearchModel } from './model';

const { actions, selectors } = messagesSearchModel;

export const {
  setMessagesSearchResult,
  resetMessagesSearchResult,
  startMessagesSearch,
  resetMessagesSearch,
  switchSearchedMessageIndex,
} = actions;
export const { getMessagesSearchState, getMessagesSearchActive } = selectors;

export type {
  MessagesSearchModelState,
  SearchedDialogMessagesState,
  SearchMessagesDirection,
  SearchedDialogMessage,
} from './types';
export { mapSearchedDialogMessagesDto } from './adapter';

export { messagesSearchModel as messagesSearchSlice };
