import { AutoGeneratedAnswerOptionDto, AutoGeneratedAnswersDto } from 'modules/api/dto';

import { checkIsSpecialOption } from '../helpers';

import { CopilotData, CopilotOption } from './types';

const mapOptionDtoToCopilotOption = (
  dto: AutoGeneratedAnswerOptionDto,
  index: number,
): CopilotOption => ({
  id: `${dto.name}_${dto.type}_${Date.now()}_${index}`,
  text: dto.name,
  type: dto.type,
});

export const mapAutoGeneratedAnswersDto = (dto: AutoGeneratedAnswersDto): CopilotData => {
  const options: CopilotOption[] = [];
  let specialOption: CopilotOption | undefined;

  dto.options.forEach((optionDto, index) => {
    const option = mapOptionDtoToCopilotOption(optionDto, index);

    if (checkIsSpecialOption(option)) {
      specialOption = option;
    } else {
      options.push(option);
    }
  });

  return {
    botName: dto.botName,
    options,
    specialOption,
  };
};
