import React, { memo, useMemo } from 'react';

import { PageBodyWithTitle } from 'modules/components/common/page-body-with-title';
import { useEarningsInfo, useEarningsActualize } from 'modules/domain/earnings/hooks';
import { useStatsContext } from 'modules/components/stats-page/context';
import { useOperatorCommissionInfo } from 'modules/domain/operator/hooks';

import { calculateIncomeData } from './calculate-income-data';
import { Card } from './card';
import styles from './index.module.css';

export const IncomePage = memo(() => {
  const { selectedRange, showKPI } = useStatsContext();

  useEarningsActualize(selectedRange);

  const commissionInfo = useOperatorCommissionInfo();
  const earnings = useEarningsInfo(selectedRange);

  const incomeData = useMemo(() => calculateIncomeData(earnings), [earnings]);

  const {
    repliesNumber,
    penalties,
    repliesPayment,
    benefitsNumber,
    benefitsPayment,
    kpiEffectPayment,
    totalIncome,
  } = incomeData;

  return (
    <div className={styles.container}>
      <PageBodyWithTitle title="Income">
        {earnings && commissionInfo && (
          <>
            <Card
              explanationValue={repliesNumber}
              money={repliesPayment}
              explanation="Replies:"
              explanationDetails={`You receive $${commissionInfo.commission} when client reply on your message, photo or gift`}
              testIdPostfix="replies"
            />
            <Card
              explanationValue={benefitsNumber}
              money={benefitsPayment}
              explanation="Extra Benefits:"
              explanationDetails={`You receive $${commissionInfo.commission} when client open your photo, reply by gift on your gift etc.`}
              testIdPostfix="extra-benefits"
            />
            <Card
              explanationValue={showKPI ? `${earnings.kpiEffect}%` : ''}
              money={kpiEffectPayment}
              explanation={showKPI ? 'KPI Effect:' : 'KPI Effect'}
              explanationDetails="Increase or decrease your income from Replies"
              negative={kpiEffectPayment < 0 || earnings.kpiEffect < 0}
              positive={kpiEffectPayment > 0}
              testIdPostfix="kpi-effect"
            />
            <Card
              explanationValue={earnings.trapPenalties?.bonusesCount || 0}
              money={penalties}
              explanation="Penalties:"
              explanationDetails={`You lose $${Math.abs(
                commissionInfo.trapPenalty,
              )} when you choose an inappropriate answer`}
              negative={!!earnings.trapPenalties?.totalBonusValue}
              testIdPostfix="penalties"
            />
            <Card
              money={totalIncome}
              explanation="Total Income"
              testIdPostfix="total-income"
              negative={totalIncome < 0}
            />
          </>
        )}
      </PageBodyWithTitle>
    </div>
  );
});
