import { DialogMessageDto, DialogMessageMetaDto } from 'modules/api/dto';
import { generateMessageTag } from 'modules/utils';

export type CreateMessageDraftData = {
  sender: string;
  recipient: string;
  text?: string;
  meta?: DialogMessageMetaDto;
};

export const createMessageDraft = ({
  sender,
  recipient,
  text = '',
  meta = {},
}: CreateMessageDraftData): DialogMessageDto => ({
  id: Date.now(),
  tag: generateMessageTag(sender),
  sender,
  recipient,
  text,
  timestamp: new Date().getTime(),
  meta,
});
