import { ActionWithUserId } from 'modules/domain/common/types';

import { User, UsersModelState } from './types';

export const updateUserState =
  <Action extends ActionWithUserId = ActionWithUserId>(
    updateFn: (userState: User, payload: Action['payload']) => void,
  ) =>
  (state: UsersModelState, { payload }: Action) => {
    const userState = state[payload.userId];
    if (!userState) {
      return;
    }

    updateFn(userState, payload);
  };
