import { chatInputModel } from './model';

const { actions, selectors } = chatInputModel;

export const { setInputFocused, setInputText, resetInputText } = actions;
export const { getInputFocused, getInputText } = selectors;

export type { ChatInputModelState } from './types';

export { chatInputModel as chatInputSlice };
