import { RefObject, useEffect } from 'react';

import { useSelectedFastAnswer } from 'modules/domain/fast-answers/hooks';
import { useDialogSomeMessagesLoaded } from 'modules/domain/messages/hooks';
import { useCopilotLastUsageData } from 'modules/domain/copilot/hooks';

type InputRef = RefObject<HTMLInputElement>;

const useFocusWithCondition = (inputRef: InputRef, condition: any) => {
  useEffect(() => {
    if (condition) {
      inputRef.current?.focus();
    }
  }, [inputRef, condition]);
};

export const useChatInputFocuser = (dialogId: string, inputRef: RefObject<HTMLInputElement>) => {
  const someMessagesLoaded = useDialogSomeMessagesLoaded(dialogId);
  const selectedFastAnswer = useSelectedFastAnswer();
  const copilotSelectedOption = useCopilotLastUsageData()?.option;

  useFocusWithCondition(inputRef, someMessagesLoaded);
  useFocusWithCondition(inputRef, selectedFastAnswer);
  useFocusWithCondition(inputRef, copilotSelectedOption);
};
