import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { union } from 'lodash';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithUserId } from 'modules/domain/common/types';

import { User, UserExperimentTag, UsersModelState, UserTag } from './types';
import { updateUserState } from './helpers';

type SetUserAction = PayloadAction<User>;
type SetUserTagsAction = ActionWithUserId<{ tags: UserTag[] }>;
type SetUserExperimentTagsAction = ActionWithUserId<{ tags: UserExperimentTag[] }>;
type SetUserInterestsAction = ActionWithUserId<{ interests: string[] }>;
type SetUserLifeStoryAction = ActionWithUserId<{ lifeStory: string | null }>;

const initialState: UsersModelState = {};

export const usersModel = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action: SetUserAction) => {
      const user = action.payload;
      state[user.id] = user;
    },
    setUserTags: updateUserState<SetUserTagsAction>((userState, { tags }) => {
      userState.tags = union(tags, userState.tags);
    }),
    setUserExperimentTags: updateUserState<SetUserExperimentTagsAction>((userState, { tags }) => {
      userState.experiments = union(tags, userState.experiments);
    }),
    setUserInterests: updateUserState<SetUserInterestsAction>((userState, { interests }) => {
      userState.info.interests = interests;
    }),
    setUserLifeStory: updateUserState<SetUserLifeStoryAction>((userState, { lifeStory }) => {
      userState.info.lifeStory = lifeStory;
    }),
  },
  extraReducers: addResetDialogStateReducer((state, { payload: { operatorId } }) => {
    const operatorState = state[operatorId];
    // reset all users state but operator
    return { [operatorId]: operatorState };
  }),
  selectors: {
    getUser: (state, userId: string) => state[userId],
  },
});
