import React from 'react';
import cn from 'classnames';

import { testId, formatMoney } from 'modules/utils';

import styles from './index.module.css';

type Props = {
  money: number;
  explanation: string;
  explanationDetails?: string;
  explanationValue?: string | number;
  negative?: boolean;
  positive?: boolean;
  // for autotests
  testIdPostfix: string;
};

export const Card = (props: Props) => {
  const {
    money,
    explanation,
    explanationValue,
    explanationDetails,
    testIdPostfix,
    negative,
    positive,
  } = props;
  return (
    <div className={styles.body}>
      <div className={styles.explanation}>
        <div
          className={styles.explanationTitle}
          {...testId(`income-page-card-explanation-title-${testIdPostfix}`)}
        >
          {explanation}
          <span
            className={cn(styles.explanationValue, {
              [styles.badValue]: negative,
              [styles.goodValue]: positive,
            })}
            {...testId(`income-page-card-explanation-value-${testIdPostfix}`)}
          >
            {explanationValue}
          </span>
        </div>
        <div className={styles.explanationDetails}>{explanationDetails}</div>
      </div>
      <div
        className={cn(styles.money, {
          [styles.badValue]: negative,
          [styles.goodValue]: positive,
        })}
        {...testId(`income-page-card-money-${testIdPostfix}`)}
      >
        {positive ? '+' : ''}
        {formatMoney(money)}
      </div>
    </div>
  );
};
