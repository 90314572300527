import { fastAnswersModel } from './model';

const { actions, selectors } = fastAnswersModel;

export const { setFastAnswers, setFastAnswerSelected, resetSelectedFastAnswer } = actions;
export const { getFastAnswers, getSelectedFastAnswer } = selectors;

export type {
  FastAnswer,
  FastAnswersList,
  FastAnswerShortcut,
  FastAnswersModelState,
} from './types';
export { mapFastAnswersDtoToList, mapFastAnswersListToDto } from './adapter';

export { fastAnswersModel as fastAnswersSlice };
