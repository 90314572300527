import { operatorModel } from './model';

const { actions, selectors } = operatorModel;

export const { setOperatorInfo, setOperatorCommissionInfo, setOperatorNeedOnboarding } = actions;
export const { getOperatorInfo, getOperatorCommissionInfo, getOperatorNeedOnboarding } = selectors;

export type { OperatorInfo, OperatorCommissionInfo, OperatorModelState } from './types';

export { operatorModel as operatorSlice };
