import { audioMessagesModel } from './model';

const { actions, selectors } = audioMessagesModel;

export const { setAudioMessages, resetAudioMessages } = actions;
export const { getAudioMessagesState } = selectors;

export type { AudioMessageTemplate, AudioMessagesModelState } from './types';
export { mapAudioMessageTemplateDto } from './adapter';

export { audioMessagesModel as audioMessagesSlice };
