import { useMemo } from 'react';

import { interestsDictionaryAddition } from 'modules/domain/dictionaries/constants';
import {
  getDictionaries,
  DictionariesList,
  DictionaryName,
} from 'modules/domain/dictionaries/model';
import { User } from 'modules/domain/users/model';
import { UserCommunicationPurpose } from 'modules/api/dto';
import { useRootSelector } from 'modules/hooks/redux';

import { useUserData } from './use-user-data';

export const useLocalizedUserData = (userId: string) => {
  const user = useUserData(userId);
  const dictionaries = useRootSelector(getDictionaries);

  const localizedUser = useMemo(
    () => localizeUserFromDictionary(user, dictionaries),
    [user, dictionaries],
  );

  return localizedUser;
};

function localizeUserFromDictionary(
  user: User | undefined,
  dictionaries: DictionariesList,
): User | undefined {
  if (!user || !Object.keys(dictionaries)) {
    return user;
  }

  const mergedDictionaries: DictionariesList = {
    ...dictionaries,
    interests: { ...dictionaries.interests, ...interestsDictionaryAddition },
  };

  const getValueFromDict = (dictName: DictionaryName, value: string) =>
    mergedDictionaries[dictName]?.[value] || value;

  const { info } = user;

  return {
    ...user,
    info: {
      ...info,
      eye: getValueFromDict(DictionaryName.Eyes, info.eye),
      hair: getValueFromDict(DictionaryName.Hairs, info.hair),
      drink: getValueFromDict(DictionaryName.Drinking, info.drink),
      smoke: getValueFromDict(DictionaryName.Smoke, info.smoke),
      education: getValueFromDict(DictionaryName.Educations, info.education),
      relationship: getValueFromDict(DictionaryName.Relationships, info.relationship),
      bodytype: getValueFromDict(DictionaryName.BodyType, info.bodytype),
      languages: info.languages.map(lang => getValueFromDict(DictionaryName.Languages, lang)),
      interests: info.interests.map(interest =>
        getValueFromDict(DictionaryName.Interests, interest),
      ),
      communicationPurpose: getValueFromDict(
        DictionaryName.CommunicationPurposes,
        info.communicationPurpose as UserCommunicationPurpose,
      ),
    },
  };
}
