import React from 'react';

import { LoaderIcon } from 'modules/components/icon/loader';

import styles from './index.module.css';

type Props = {
  size?: number;
};

export const Loader = (props: Props) => {
  const { size } = props;

  return (
    <div className={styles.loader}>
      <LoaderIcon size={size} />
    </div>
  );
};
