import { useCallback } from 'react';

import { useDispatchAction } from 'modules/hooks/redux';
import { useDialogLatestMessage } from 'modules/domain/messages/hooks';
import { parseDialogId } from 'modules/utils';
import { AutomationProductName } from 'modules/api/dto';
import {
  CopilotAnalyticsData,
  resetCopilotLastUsage as resetCopilotLastUsageAction,
} from 'modules/domain/copilot/model';

import { useCopilotDataState, useCopilotLastUsageData } from '../selectors';

import {
  transformMessageTypeForCopilotAnalytics,
  getCopilotUsage,
  transformOptionsForCopilotAnalytics,
  checkCopilotLoaded,
  checkSpecialOptionUsed,
} from './helpers';
import { useCopilotAnalyticsApi } from './use-copilot-analytics-api';

export const useCopilotAnalytics = (dialogId: string) => {
  const { animatorId, attendeeId } = parseDialogId(dialogId);

  const resetCopilotLastUsage = useDispatchAction(resetCopilotLastUsageAction);
  const { trackCopilotUsage } = useCopilotAnalyticsApi(animatorId, attendeeId);

  const lastMessage = useDialogLatestMessage(dialogId);
  const actualCopilotData = useCopilotDataState(animatorId, attendeeId, lastMessage?.id || 0);
  const copilotLastUsageData = useCopilotLastUsageData();

  const trackCopilotUsageAndInjectMeta = useCallback(
    (analyticsData: CopilotAnalyticsData) => {
      if (!copilotLastUsageData && !checkCopilotLoaded(actualCopilotData)) {
        return;
      }

      const { type: messageType, copilotUsed, message } = analyticsData;

      const messageTypeForCopilot = transformMessageTypeForCopilotAnalytics(
        messageType,
        copilotUsed,
      );

      const specialOptionUsed = checkSpecialOptionUsed(messageTypeForCopilot);

      const usage = getCopilotUsage(specialOptionUsed, copilotLastUsageData);

      const { copilotData: lastUsedCopilotData, option: lastSelectedOption } =
        copilotLastUsageData || {};

      const { options = [], specialOption, botName } = lastUsedCopilotData || actualCopilotData!;

      const optionsTexts = transformOptionsForCopilotAnalytics(options, specialOption);

      message.meta.automation = {
        product: AutomationProductName.Copilot,
        bot: botName,
        usage,
      };

      const selectedOption = specialOptionUsed ? messageTypeForCopilot : lastSelectedOption?.text;

      trackCopilotUsage({
        message: message.text,
        tag: message.tag,
        type: messageTypeForCopilot,
        options: optionsTexts,
        botName,
        selectedOption,
      });

      resetCopilotLastUsage();
    },
    [trackCopilotUsage, actualCopilotData, copilotLastUsageData, resetCopilotLastUsage],
  );

  return { trackCopilotUsageAndInjectMeta };
};
