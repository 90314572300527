import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { parseDialogId, getMessageTypeFromFile, useLogger } from 'modules/utils';
import { DialogMessageDto, DialogMessageMetaDto } from 'modules/api/dto';

import { getPhotoReference } from '../helpers';
import {
  MessageType,
  setUploadMessageMediaProgress,
  setUploadMessageMediaStart,
  setUploadMessageMediaFinish,
  mapDialogMessageDto,
} from '../model';

const makeDraftMessageCopy = (
  draftMessage: DialogMessageDto,
  meta?: Pick<DialogMessageMetaDto, 'localPhotoUri' | 'reference'>,
): DialogMessageDto => {
  const draftMessageCopy: DialogMessageDto = {
    ...draftMessage,
    meta: {
      ...draftMessage.meta,
      ...meta,
    },
  };

  return draftMessageCopy;
};

export const useUploadDialogMessageMedia = (dialogId: string) => {
  const dispatch = useDispatch();
  const { dialogsMedia: dialogsMediaApi } = useApi();
  const { logError } = useLogger('useUploadDialogMessageMedia');

  const uploadMessageMediaAndUpdateDraft = useCallback(
    async (draftMessage: DialogMessageDto, file: File) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      const mediaUrl = URL.createObjectURL(file);
      const mediaType = getMessageTypeFromFile(file);

      if (mediaType !== MessageType.PHOTO) {
        throw new Error(`Unsupported media type: ${mediaType}`);
      }

      const draftMessageWithLocalPhotoUri = makeDraftMessageCopy(draftMessage, {
        localPhotoUri: mediaUrl,
      });

      const messageTag = draftMessage.tag;

      const message = mapDialogMessageDto(draftMessageWithLocalPhotoUri, animatorId);
      dispatch(setUploadMessageMediaStart({ dialogId, message }));

      try {
        const basename = await dialogsMediaApi.uploadDialogMedia(
          animatorId,
          attendeeId,
          file,
          progress => {
            dispatch(setUploadMessageMediaProgress({ dialogId, messageTag, progress }));
          },
        );

        const draftMessageWithReference = makeDraftMessageCopy(draftMessage, {
          reference: getPhotoReference(basename),
        });

        return draftMessageWithReference;
      } catch (error) {
        logError('Failed to upload dialog message media', {
          error,
          message: draftMessage,
          dialogId,
        });

        throw error;
      } finally {
        dispatch(setUploadMessageMediaFinish({ dialogId, messageTag }));
      }
    },
    [dispatch, dialogsMediaApi, dialogId, logError],
  );

  return { uploadMessageMediaAndUpdateDraft };
};
