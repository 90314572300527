import React, { ComponentProps } from 'react';

import { UserChatRole } from 'modules/constants';

import { AccordionSection } from '../accordion-section';

import styles from './styles.module.scss';

type AccordionSectionProps = ComponentProps<typeof AccordionSection>;

export type TabItemProps = Omit<AccordionSectionProps, 'isAnimator' | 'content'> & {
  enabled: boolean;
  render: () => AccordionSectionProps['content'];
};

type Props = {
  tabs: TabItemProps[];
  type: UserChatRole;
};

export const TabsList = (props: Props) => {
  const { tabs, type } = props;

  return (
    <div className={styles.accordionContainer}>
      {tabs
        .filter(section => section.enabled)
        .map(({ id, title, render }) => (
          <AccordionSection
            key={id}
            title={title}
            content={render()}
            id={id}
            isAnimator={type === 'animator'}
          />
        ))}
    </div>
  );
};
