import { useCallback } from 'react';

import { parseDialogId } from 'modules/utils';
import { useCopilotAnalytics } from 'modules/domain/copilot/hooks';
import { useFastAnswersAnalytics } from 'modules/domain/fast-answers/hooks';

import { MessageType, SendDialogMessageData } from '../model';
import { createMessageDraft } from '../helpers';

import { useSendDialogMessageApi } from './use-send-dialog-message-api';
import { useUploadDialogMessageMedia } from './use-dialog-media-upload';

export const useSendDialogMessage = (dialogId: string) => {
  const { sendDialogMessage: apiSendDialogMessage } = useSendDialogMessageApi(dialogId);
  const { trackCopilotUsageAndInjectMeta } = useCopilotAnalytics(dialogId);
  const { trackFastAnswersUsage } = useFastAnswersAnalytics();
  const { uploadMessageMediaAndUpdateDraft } = useUploadDialogMessageMedia(dialogId);

  const sendDialogMessage = useCallback(
    async (data: SendDialogMessageData) => {
      const { meta, file, text, copilotUsed, type } = data;

      const { animatorId, attendeeId } = parseDialogId(dialogId);

      let messageDraft = createMessageDraft({
        sender: animatorId,
        recipient: attendeeId,
        text,
        meta,
      });

      trackCopilotUsageAndInjectMeta({ message: messageDraft, type, copilotUsed });

      if (type === MessageType.TEXT) {
        trackFastAnswersUsage(messageDraft);
      }

      if (type === MessageType.PHOTO && file) {
        messageDraft = await uploadMessageMediaAndUpdateDraft(messageDraft, file);
      }

      return apiSendDialogMessage(messageDraft);
    },
    [
      apiSendDialogMessage,
      trackCopilotUsageAndInjectMeta,
      uploadMessageMediaAndUpdateDraft,
      trackFastAnswersUsage,
      dialogId,
    ],
  );

  return sendDialogMessage;
};
