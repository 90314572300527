import { earningsModel } from './model';

const { actions, selectors } = earningsModel;

export const { setEarnings, setKpi } = actions;
export const { getEarnings, getKpi } = selectors;

export type { EarningsInfo, KpiFullInfo, EarningsModelState } from './types';
export { DatesRange } from './types';
export { mapEarningsDto, mapKpiDto } from './adapter';

export { earningsModel as earningsSlice };
