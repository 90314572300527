import { usersModel } from './model';

const { actions, selectors } = usersModel;

export const { setUser, setUserExperimentTags, setUserInterests, setUserLifeStory, setUserTags } =
  actions;
export const { getUser } = selectors;

export type { User, UserInfo, UsersModelState, UserGender } from './types';
export { UserTag, UserExperimentTag } from './types';

export { mapUserDtoToUser } from './adapter';

export { usersModel as usersSlice };
