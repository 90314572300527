import React from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils';
import { SpotifyMatchResult, SpotifyScoreLevel } from 'modules/domain/spotify-match/model';

import { ContentItem } from './content-item';
import styles from './index.module.scss';

type Props = {
  attendeeId: string;
  matchResult: SpotifyMatchResult;
};

const listToString = (list: string[]) => list.join(', ');

export const AccordionTabSpotifyMatch = (props: Props) => {
  const { attendeeId, matchResult } = props;

  const { level, score, genres, artists, tracks } = matchResult;

  return (
    <div {...testId(`user-tab-spotify-match-${attendeeId}`)} className={styles.container}>
      <div {...testId('user-tab-spotify-match-title')} className={styles.title}>
        Your taste match is{' '}
        <span
          className={cn(styles.bubble, {
            [styles.high]: level === SpotifyScoreLevel.High,
            [styles.medium]: level === SpotifyScoreLevel.Medium,
            [styles.low]: level === SpotifyScoreLevel.Low,
          })}
        >
          {score}
        </span>
      </div>

      <ContentItem testIdKey="genres" title="Genres" content={listToString(genres)} />
      <ContentItem testIdKey="artists" title="Artists" content={listToString(artists)} />
      <ContentItem testIdKey="tracks" title="Tracks" content={listToString(tracks)} />
    </div>
  );
};
