import React from 'react';
import cn from 'classnames';

import { useUserData } from 'modules/domain/users/hooks';
import { UserChatRole } from 'modules/constants';
import { testId } from 'modules/utils';

import { AnimatorPreferences } from '../animator-preferences';
import { Avatar } from '../avatar';
import { OccupationInfo } from '../occupation-info';

import styles from './index.module.css';

type Props = {
  attendeeId: string;
  animatorId: string;
  type: UserChatRole;
  location: string | undefined;
};

export const UserHeaderBase = (props: Props) => {
  const { animatorId, attendeeId, type, location } = props;
  const isAnimator = type === 'animator';
  const userId = isAnimator ? animatorId : attendeeId;
  const { name, gender, age, occupation } = useUserData(userId) || {};
  const nameAndAge = [name, age].filter(Boolean).join(', ');

  return (
    <div
      {...testId(`user-header-${type}`)}
      className={cn(styles.userHeader, {
        [styles.male]: gender === 'mal',
        [styles.female]: gender === 'fem',
      })}
    >
      <div className={styles.generalInfo}>
        <Avatar userId={userId} />
        <div className={styles.textInfo}>
          <div className={styles.nameAndAge} {...testId(`user-name-and-age-${userId}`)}>
            {nameAndAge}
          </div>
          <div className={styles.location} {...testId(`user-location-${userId}`)}>
            {location || 'City is not specified'}
          </div>
          <OccupationInfo occupation={occupation || ''} isAnimator={isAnimator} userId={userId} />
        </div>
      </div>
      <div className={styles.iconContainer}>{isAnimator && <AnimatorPreferences />}</div>
    </div>
  );
};
