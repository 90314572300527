import React from 'react';
import { Formik, Form, Field, FormikConfig } from 'formik';

import { TextInput } from 'modules/components/common/text-input';
import { Button } from 'modules/components/common/button/base';
import { CheckboxInput } from 'modules/components/common/checkbox-input';

import { AuthFormValues } from './types';
import { initialFormState, validate } from './form';
import styles from './index.module.scss';

type Props = {
  handleSubmit: FormikConfig<AuthFormValues>['onSubmit'];
};

export default (props: Props) => {
  const { handleSubmit } = props;

  return (
    <div className={styles.container}>
      <Formik<AuthFormValues>
        initialValues={initialFormState}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field type="email" name="email" placeholder="Login" component={TextInput} />
            <Field type="password" name="password" placeholder="Password" component={TextInput} />
            <div className={styles.row}>
              <Field
                type="checkbox"
                name="remember"
                caption="Remember me"
                component={CheckboxInput}
              />
              <Button type="submit" size="lg" theme="green" disabled={isSubmitting}>
                Sign In
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
