import { useCallback } from 'react';

import { replaceByMask } from 'modules/utils';
import { useDispatchAction } from 'modules/hooks/redux';
import { useCurrentDialogUsersIds } from 'modules/domain/dialog/hooks';
import { useUserField } from 'modules/domain/users/hooks';
import { useChatInputActions } from 'modules/domain/chat-input/hooks';

import { FastAnswer, setFastAnswerSelected as setFastAnswerSelectedAction } from '../model';

export const useFastAnswerSelectAction = () => {
  const { attendeeId } = useCurrentDialogUsersIds();
  const attendeeName = useUserField(attendeeId, 'name');
  const { setInputText } = useChatInputActions();
  const setFastAnswerSelected = useDispatchAction(setFastAnswerSelectedAction);

  const selectFastAnswer = useCallback(
    (fastAnswer: FastAnswer) => {
      const message = replaceByMask(fastAnswer.phrase, '%name%', attendeeName || '');
      setInputText(message, true);
      setFastAnswerSelected({ fastAnswer });
    },
    [setFastAnswerSelected, setInputText, attendeeName],
  );

  return selectFastAnswer;
};
