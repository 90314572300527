import React from 'react';

import { UserChatRole } from 'modules/constants';

import { AccordionTabInfo } from '../components/sections/info';
import { AccordionTabNotes } from '../components/sections/notes';
import { AccordionTabPhotos } from '../components/sections/photos';
import { TabItemProps } from '../components/tabs-list';

export const useBaseTabsList = (animatorId: string, attendeeId: string, role: UserChatRole) => {
  const ownerId = role === 'animator' ? animatorId : attendeeId;

  const baseTabsList: TabItemProps[] = [
    {
      id: 'notes',
      title: 'Notes',
      enabled: true,
      render: () => <AccordionTabNotes type={role} />,
    },
    {
      id: 'info',
      title: 'Info',
      enabled: true,
      render: () => <AccordionTabInfo ownerId={ownerId} attendeeId={attendeeId} />,
    },
    {
      id: 'photo',
      title: 'Photos',
      enabled: true,
      render: () => <AccordionTabPhotos ownerId={ownerId} />,
    },
  ];

  return baseTabsList;
};
