import React from 'react';
import Highlighter from 'react-highlight-words';
import classNames from 'classnames';

import { testId } from 'modules/utils';

import { useSearchResultHighlight } from './use-search-result-highlight';
import styles from './index.module.scss';

type Props = {
  dialogId: string;
  messageId: number;
  content: string;
  a11yLabel?: string;
  outgoing?: boolean;
  error?: boolean;
  className?: string;
};

export const TextMessage = (props: Props) => {
  const { dialogId, messageId, content, outgoing, className, a11yLabel, error } = props;

  const { highlightedText, isInFocus } = useSearchResultHighlight(dialogId, messageId);

  const testIdPrefix = a11yLabel ? `${a11yLabel}-` : '';

  const messageClassName = classNames(styles.chatTextMessage, className, {
    [styles.outgoing]: outgoing,
    [styles.error]: error,
  });

  return highlightedText ? (
    <Highlighter
      {...testId(`${testIdPrefix}highlighted-message-text`)}
      className={classNames(styles.chatTextMessage, className, {
        [styles.outgoing]: outgoing,
        [styles.error]: error,
      })}
      highlightClassName={
        isInFocus ? styles.chatTextMessageActiveHighlight : styles.chatTextMessageHighlight
      }
      searchWords={[highlightedText]}
      textToHighlight={content}
    />
  ) : (
    <div {...testId(`${testIdPrefix}message-text`)} className={messageClassName}>
      {content}
    </div>
  );
};
