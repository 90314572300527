import React, { InputHTMLAttributes } from 'react';
import { FieldAttributes, FormikProps, FormikValues } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './index.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  caption?: string;
  field: FieldAttributes<any>;
  form: FormikProps<FormikValues>;
};

export const CheckboxInput = (props: Props) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    form,
    caption,
    field,
    ...inputProps
  } = props;

  return (
    <label className={styles.container}>
      <input {...field} {...inputProps} />
      <span className={styles.checkbox}>
        <FontAwesomeIcon icon={faCheck} />
      </span>
      {caption && <span className={styles.caption}>{caption}</span>}
    </label>
  );
};
