import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';

import { ChatInputModelState } from './types';
import { setInputTextState } from './helpers';

type SetInputTextAction = PayloadAction<{ inputText: string; append?: boolean }>;

const initialState: ChatInputModelState = {
  inputFocused: false,
  inputText: '',
};

export const chatInputModel = createSlice({
  name: 'chatInput',
  initialState,
  reducers: {
    resetInputText: state => {
      state.inputText = initialState.inputText;
    },
    setInputFocused: (state, { payload }: PayloadAction<boolean>) => {
      state.inputFocused = payload;
    },
    setInputText: (state, { payload: { inputText, append } }: SetInputTextAction) => {
      setInputTextState(state, inputText, append);
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getInputText: state => state.inputText,
    getInputFocused: state => state.inputFocused,
  },
});
