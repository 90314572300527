import { modalsModel } from './model';

const { actions, selectors } = modalsModel;

export const { setActiveModal, closeActiveModal } = actions;
export const { getActiveModalData, getActiveModalName } = selectors;

export type { ModalsModelState } from './types';
export { ModalNames } from './types';

export { modalsModel as modalsSlice };
