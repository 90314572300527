import React, { memo, useCallback, useMemo } from 'react';

import {
  useFastAnswerSelectAction,
  useOperatorFastAnswers,
} from 'modules/domain/fast-answers/hooks';
import { useShortcutListener } from 'modules/components/common/hooks';
import { ModalNames } from 'modules/domain/modals/model';
import { useModalActions } from 'modules/domain/modals/hooks';

import { FastAnswersAddButton } from './add-button';
import { FastAnswersSlider } from './slider';
import styles from './index.module.scss';

export const FastAnswers = memo(() => {
  const { fastAnswers } = useOperatorFastAnswers();
  const selectFastAnswer = useFastAnswerSelectAction();
  const { openModal } = useModalActions();

  const handleShortcutPress = useCallback(
    (keyNumber: number) => {
      const foundFastAnswer = fastAnswers?.find(item => keyNumber === item.shortcutDigit);

      if (foundFastAnswer) {
        selectFastAnswer(foundFastAnswer);
      }
    },
    [fastAnswers, selectFastAnswer],
  );

  useShortcutListener(handleShortcutPress, 'ctrlKey');

  const handleAddBtnClick = () => {
    openModal(ModalNames.FastAnswers);
  };

  const filledFastAnswers = useMemo(() => {
    return fastAnswers?.filter(fastAnswer => fastAnswer.phrase.length);
  }, [fastAnswers]);

  const hasFastAnswers = !!filledFastAnswers?.length;

  return (
    <div className={styles.fastAnswers}>
      <FastAnswersAddButton
        variant={hasFastAnswers ? 'default' : 'extended'}
        onClick={handleAddBtnClick}
      />
      {hasFastAnswers && (
        <FastAnswersSlider fastAnswers={filledFastAnswers} onFastAnswerClick={selectFastAnswer} />
      )}
    </div>
  );
});
