import React from 'react';

import { Image } from 'modules/components/image';
import { ImageOverlay } from 'modules/components/image-overlay';
import { CHAT_IMAGE_SIZE } from 'modules/constants';

import { ImageParams, useImageUri } from './use-image-uri';
import styles from './index.module.scss';

type Props = {
  basename: string;
  sender: string;
  recipient: string;
};

export const ImageMessage = (props: Props) => {
  const { basename, sender, recipient } = props;

  const imageParams: ImageParams = {
    basename,
    recipient,
    sender,
  };

  const imageSrc = useImageUri({ ...imageParams, size: CHAT_IMAGE_SIZE });
  const overlayImageSrc = useImageUri(imageParams);

  return (
    <ImageOverlay className={styles.overlay} src={overlayImageSrc}>
      <Image
        a11yLabel={`chat-image-message-${basename}`}
        src={imageSrc}
        alt={basename}
        className={styles.chatImageMessage}
      />
    </ImageOverlay>
  );
};
