import { useCallback } from 'react';

import { useAnnalsApi, useApi } from 'modules/api';
import { DialogMessageDto, FastAnswersDto } from 'modules/api/dto';
import { useDispatchAction } from 'modules/hooks/redux';

import { resetSelectedFastAnswer as resetSelectedFastAnswerAction } from '../model';

import { useSelectedFastAnswer } from './selectors';

export const useFastAnswersAnalytics = () => {
  const { realm } = useApi();
  const { send, sendWithOperatorId } = useAnnalsApi();
  const resetSelectedFastAnswer = useDispatchAction(resetSelectedFastAnswerAction);

  const selectedFastAnswer = useSelectedFastAnswer();

  const trackFastAnswersUsage = useCallback(
    async (message: DialogMessageDto) => {
      if (!selectedFastAnswer) return;

      const payload = {
        'client-id': message.recipient,
        message: message.text,
        tag: message.tag,
        'fast-answer': {
          phrase: selectedFastAnswer.phrase,
          hotkey: selectedFastAnswer.shortcut,
          realm,
        },
      };

      await sendWithOperatorId('multichat-sent-message-with-fast-answer', payload);

      resetSelectedFastAnswer();
    },
    [sendWithOperatorId, realm, selectedFastAnswer, resetSelectedFastAnswer],
  );

  const trackFastAnswersChanged = useCallback(
    (fastAnswersPayload: FastAnswersDto) => {
      return send('multichat-fast-answers-changed', {
        content: fastAnswersPayload,
      });
    },
    [send],
  );

  return { trackFastAnswersChanged, trackFastAnswersUsage };
};
